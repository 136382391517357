import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import FirebaseApp from '../firebaseApp.js';
import { StoryGraphPlayer, jsonToStoryGraph } from "./StoryGraphCore.js";
import "./StoryView.css";
import 'animate.css/animate.css';

export default function StoryView(props) {
  let storyId = useParams().storyId;
  if (props.storyId) {
    storyId = props.storyId;
  }
  const vidHeight = props.vidHeight || "100vh";
  const db = FirebaseApp.firestore();
  let docRef = db.collection("stories").doc(storyId);
  const ref = useRef(null);
  const [user, setUser] = useState("");
  const [title, setTitle] = useState("");
  useEffect(() => {
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          console.log(doc.data());
          const data = doc.data();
          const story = data.story;
          setUser(data.user);
          setTitle(data.title);
          const storyGraphObj = jsonToStoryGraph(data);
          const storyGraphPlayer = new StoryGraphPlayer({
            container: ref.current,
            storyGraphObj,
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, []);
  return (
    <div>
      <div
        style={{ height: vidHeight, width: "100%", backgroundColor: "black" }}
        ref={ref}
      ></div>
      <StoryHeader title={title} user={user} />
    </div>
  );
}

function StoryHeader(props) {
  const { title, user } = props;
  return (
    <div className="story-view-header">
      <p style={{ fontSize: 16, fontWeight: 500 }}>{title}</p>
      <small style={{ fontSize: 14 }}>by {user}</small>
    </div>
  );
}
