import React, {useState} from 'react';
import { Link } from "react-router-dom";
import LOGO from '../img/storygraph.png';

export default function Navbar(props){
  const {hideLogo} = props;
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  return(    <nav className="navbar">
      <div className="container">
        <div className="navbar-brand">
          {!hideLogo &&<Link to="/" className="navbar-item" href="#">
            <img src={LOGO} />
            (Beta)
          </Link>}
          <span onClick={()=>{setIsBurgerActive(!isBurgerActive);}} className={(isBurgerActive?"is-active ":"")+"navbar-burger burger"} data-target="navbarMenuHeroA">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
        <div id="navbarMenuHeroA" className={(isBurgerActive?"is-active ":"")+"navbar-menu"}>
          <div className="navbar-end">
            <Link to="/builder" className="navbar-item">
              Builder
            </Link>
            <Link to="/explore" className="navbar-item">
              Explore
            </Link>
            <a href="/#contact" className="navbar-item">
              Contact
            </a>
          </div>
        </div>
      </div>
    </nav>)
}