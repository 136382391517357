import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import GoogleSignInButton from "./templates/GoogleSignInButton";
import ExplorePage from "./pages/ExplorePage";
import LandingPage from "./pages/LandingPage";
import BuilderPage from "./pages/BuilderPage";
import StoryView from "./components/StoryView";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/explore">
          <ExplorePage />
        </Route>
        <Route path="/landing">
          <LandingPage />
        </Route>
        <Route path="/builder">
          <BuilderPage />
        </Route>
        <Route path="/stories/:storyId">
          <StoryView />
        </Route>
        <Route path="/dev-component-explorer">
          <LoginPage />
        </Route>
        <Route path="/">
          <LandingPage />
        </Route>
      </Switch>
    </Router>
  );
}

function LoginPage() {
  return (
    <div>
      <h2>LoginPage</h2>
      <GoogleSignInButton />
    </div>
  );
}

export default App;
