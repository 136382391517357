import * as firebase from 'firebase/app';
//import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const FirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyD7eiLBSUY96qN6xrTFSsK9wav70VfSo_M",
    authDomain: "storygraph-98bdb.firebaseapp.com",
    databaseURL: "https://storygraph-98bdb.firebaseio.com",
    projectId: "storygraph-98bdb",
    storageBucket: "storygraph-98bdb.appspot.com",
    messagingSenderId: "1070997283497",
    appId: "1:1070997283497:web:7aa8fd6eafd504469fbfb8",
    measurementId: "G-NPY3HK13GJ"
  });
export default FirebaseApp;