import React from "react";
import Navbar from "../templates/Navbar";
import AboutModal from "../components/AboutModal";
import StoryGraphBuilder from "../components/StoryGraphBuilder"
;
export default function BuilderPage(props) {
  return (
    <div>
      <Navbar />
      <div
        style={{
          top: 60,
          right: 10,
          position: "absolute",
        }}
      >
        <AboutModal />
      </div>
      <StoryGraphBuilder />
    </div>
  );
}
