import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import FirebaseApp from '../firebaseApp.js';
import Navbar from "../templates/Navbar";
import LOGO_EXPLORE from "../img/storygraph-explore.png";
import './ExplorePage.css';

export default function ExplorePage(props) {
  const [storyGraphs, setStoryGraphs] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const db = FirebaseApp.firestore();
    db.collection("stories")
      .orderBy("order", "desc")
      .get()
      .then(function(querySnapshot) {
        let sg = [];
        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          const data = doc.data();
          sg.push({
            id: doc.id,
            src: data.thumbnailURL,
            title: data.title,
            email: data.user
          });
        });
        setStoryGraphs(sg);
      });
  }, []);

  return (
    <div className="svg-shapes">
      <Navbar hideLogo={true} />
      <PlusButton />
      <section className="section has-text-centered">
        <Link to="/">
          <img
            style={{ maxWidth: 300 }}
            src={LOGO_EXPLORE}
          />
        </Link>
        <br></br>
        <br></br>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: 500, minWidth: 290 }}>
            <div className="control has-icons-left has-icons-right">
              <input className="input is-rounded" type="text" />
              <span className="icon is-left">
                <i className="fas fa-search"></i>
              </span>
            </div>
            <div>
              <div
                style={{ padding: "0 40px", marginTop: 10 }}
                className="tags has-addons"
              >
                <span className="tag is-info">All </span>
                <a className="tag is-delete"></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section"
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <div style={styles.itemsContainer}>
          {storyGraphs.map((x, i) => (
            <div
              key={i+"Explore"}
              style={styles.item}
              onClick={() => {
                history.push(`/stories/${x.id}`);
              }}
            >
              <Card title={x.title} src={x.src} email={x.email} />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

const styles = {
  itemsContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    border: "0px solid blue",
    maxWidth: 900,
    fontSize: 20,
    fontWeight: 900
  },
  item: {
    display: "flex",
    justifyContent: "center",
    flexBasis: "33.3333%"
  },
  plusButton: {
    position: "fixed",
    bottom: 40,
    right: 20,
    borderRadius: "50%",
    zIndex: 100,
    backgroundColor: "#661ac6"
  }
};

function PlusButton() {
  return (
    <div>
      <Link
        to="/builder"
        style={styles.plusButton}
        className="button is-success is-large plus-button-shadow"
      >
        <span className="icon is-medium">
          <i className="fa fa-plus"></i>
        </span>
      </Link>
    </div>
  );
}

function Card(props) {
  const title = props.title;
  const thumbnail = props.src;
  const user = props.email;
  return (
    <div
      style={{
        background: thumbnail ? `url('${thumbnail}')` : "black",
      }}
      className="explore-card"
    >
      <div
      className="explore-card-info"
      >
        <p>{title}</p>
        <small>by {user}</small>
      </div>
    </div>
  );
}
