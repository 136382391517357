import React from "react";

export default function About() {
  return (
    <div style={{ paddingBottom: "50px" }}>
      <div className="content">
        <p>
          Welcome to the StoryGraph Builder! StoryGraph works by using
          Interactive Elements to link scenes together.This can be accomplished
          in 3 simple steps. Step 2 is the hardest part so read carefully :).
        </p>{" "}
        <h4>Steps</h4>
        <ol type="1">
          <li>
            <p>
              <strong>Upload Video</strong>
            </p>
            <p>
              {" "}
              After your scene is uploaded you can rename it
              <small>
                StoryGraph currently only supports .mp4 & .mov video file
                formats. Video clips can only be uploaded one at a time.
              </small>
            </p>
          </li>
          <li>
            <p>
              <strong>Link Scenes Together</strong>
            </p>
            <p>
              There are 3 Select Scene options for each scene. Use them to
              Select the next scene when a user clicks on the button. Use the
              label to set the text in the button and the Time of action to set
              when the buttons will appear on the screen
            </p>
          </li>
          <li>
            <p>
              <strong>Play, Save, Share</strong>
            </p>
            <p>
              Click the generate button to generate your interactive video. Play
              through to experience your masterpiece. Click save and share.
            </p>
          </li>
        </ol>
        <h3 id="terminology">Terminology</h3>
        <ul>
          <li>
            <strong>Interactive Video</strong> represents the final product and
            is composed of 1 or more Scenes.
          </li>
          <li>
            <strong>Scene</strong> represents a singly video clip along with
            it's options.
          </li>
          <li>
            <strong>Video clip</strong> represents the actual video content.
          </li>
          <li>
            <strong>Time of Action</strong> represents the moment in time during
            the Scene where the interactive options should be shown on screen.
          </li>
          <li>
            <strong>Options</strong> the interactive layer of the Scene. Example
            Option A or B. An option is composed of a label and a scene. The
            scene represents the next scene that will play when that option is
            selected.
          </li>
          <li>
            <strong>Option Label </strong> the name of an option. What shows up
            in the button on the screen.
          </li>
          <li>
            <strong>Option Scene</strong> the scene associated with an option
            label.
          </li>
        </ul>
        <h3>Other Notes</h3>
        <ul>
          <li>
            It is ok to leave some or all of the interactive options fields
            blank
          </li>
          <li>
            To restart, simple have the last scene point back to the first
          </li>
          <li>
            Ensure to have all the videos be the same aspect ratio for the best
            experience
          </li>
        </ul>
      </div>
    </div>
  );
}
