import React, { useEffect } from "react";

export default function ThumbnailGenerator(props) {
  const src = props.src;
  const onThumbnail = props.onThumbnail;
  useEffect(() => {
    if (src) {
      const vid = document.querySelector("#thumbnail-vid");
      const canvas = document.querySelector("#thumbnail-canvas");
      console.log(vid);
      console.log(src);
      vid.addEventListener("canplay", () => {
        console.log('canplay');
        setTimeout(() => {
          const aspectRatio = vid.videoWidth/vid.videoHeight;
          const width = 280;
          const height = width/aspectRatio;
          canvas.width = width;
          canvas.height = height;
          vid.width=width;
            vid.height=height;

          canvas.getContext("2d").drawImage(vid, 0, 0, width, height);

          canvas.toBlob((thumbnail)=>{
            const thumbnailSrc = URL.createObjectURL(thumbnail);
            onThumbnail({thumbnail, thumbnailSrc});
          }, "image/png");
        }, 250);
      }, {once:true});
      vid.src="";
      vid.src = src;
      vid.load();
    }
  });

  return (
    <div>
      <video
        preload="none"
        playsInline
        id="thumbnail-vid"
        style={styles.invisible}
        width="300"
        height="400"
      ></video>
      <canvas
        id="thumbnail-canvas"
        style={styles.invisible}
        width="300"
        height="400"
      ></canvas>
    </div>
  );
}

const styles = {
  invisible: {
    position: "absolute",
    top: 0,
    visibility:'hidden',
    border: "1px solid red",
    pointerEvents: "none"
  }
};
