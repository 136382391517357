import React, { useState } from "react";
import Loader from "react-loader-spinner";
import FirebaseApp from '../firebaseApp.js';
import { useHistory } from "react-router-dom";

export default function SaveAndShare(props) {
  const [isActive, setIsActive] = useState(false);
  const [email, setEmail] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [storyTitle, setStoryTitle] = useState("");
  const [storyLink, setStoryLink] = useState("");
  const nodes = props.nodes;
  const scenes = props.scenes;
  const storage = FirebaseApp.storage();
  const db = FirebaseApp.firestore();
  const storageRef = storage.ref();
  const urlPromises = [];
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);

  const save = async () => {
    setShowLoader(true);
    scenes.forEach(scene => {
      const videoFile = scene.videoFile;
      const videoName = videoFile.name;
      const refString = `video-clips/${videoName}`;
      const ref = storageRef.child(refString);
      const promise = new Promise((resolve, reject) => {
        const metaData = {
          cacheControl: "public, max-age=1000" // 1hr
        };
        ref.put(videoFile, metaData).then(async function(snapshot) {
          console.log("Uploaded a blob or file!");
          console.log(snapshot);
          const videoURL = await ref.getDownloadURL();
          resolve(videoURL);
        });
      });
      urlPromises.push(promise);
    });

    const thumbnailRef = storageRef.child(`story-thumbnail/${storyTitle}`);
    const thumbnailPromise = new Promise((resolve, reject) => {
      thumbnailRef.put(scenes[0].thumbnail).then(async function(snapshot) {
        console.log("Uploaded a blob or file!");
        console.log(snapshot);
        const thumbnailURL = await thumbnailRef.getDownloadURL();
        resolve(thumbnailURL);
      });
    });

    const thumbnailURL = await thumbnailPromise;
    const videoUrls = await Promise.all(urlPromises);
    console.log("XXX");
    console.log(nodes);
    nodes.forEach((scene, i) => {
      for (const sceneOption of scene.interactiveOptions.options) {
        if (sceneOption) {
          delete sceneOption.sceneRef;
        }
      }
      delete scene.videoFile;
      delete scene.thumbnail;
      delete scene.timeOfAction;
      delete scene.options;
      delete scene.thumbnailSrc;
      scene.src = videoUrls[i];
    });
    console.log("YYY");
    console.log(nodes);
    console.log(email);
    console.log(storyTitle);
    const storyId = await db
      .collection("stories")
      .add({
        scenes: nodes,
        start: 0,
        user: email,
        email,
        isPublic,
        thumbnailURL,
        title: storyTitle,
        createdAt: new Date().getTime(),
      })
      .then(a => a.id);
    console.log(storyId);
    setShowLoader(false);
    setIsActive(false);
    history.push(`/stories/${storyId}`);
  };

  return (
    <div>
      <button
        className="button is-warning"
        onClick={() => {
          setIsActive(true);
        }}
      >
        Save & Share
      </button>
      <div className={"modal " + (isActive ? "is-active" : "")}>
        <div className="modal-background"></div>
        <div className="modal-content" style={{ maxWidth: 300 }}>
          <div className="card">
            <div className="card-content">
              <div className="content">
                <div
                  className="control has-icons-left"
                  style={{ marginBottom: "0.5em" }}
                >
                  <input
                    className="input"
                    type="text"
                    placeholder="Title"
                    onChange={e => {
                      setStoryTitle(e.target.value);
                    }}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-film"></i>
                  </span>
                </div>
                <div
                  className="control has-icons-left"
                  style={{ marginBottom: "0.5em" }}
                >
                  <input
                    className={isValidEmail ? "input" : "input is-danger"}
                    type="text"
                    placeholder="johndoe@gmail.com"
                    onChange={e => {
                      setEmail(e.target.value);
                      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      setIsValidEmail(
                        re.test(String(e.target.value).toLowerCase())
                      );
                    }}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-user"></i>
                  </span>
                </div>
                <div style={{ textAlign: "left" }}>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={isPublic}
                      onChange={e => {
                        setIsPublic(e.target.checked);
                      }}
                    />
                    {""} isPublic
                  </label>
                </div>

                <br />
                {showLoader && (
                  <div>
                    Saving...
                    This might take a few minutes
                    <Loader
                      type="Bars"
                      color="#3a1247"
                      height={75}
                      width={75}
                      timeout={60000}
                    />
                    Once save is successful you will be redirected to your masterpiece! 
                  </div>
                )}
              </div>
            </div>
            <footer className="card-footer">
              <a
                className="card-footer-item has-text-danger"
                onClick={() => {
                  setIsActive(false);
                }}
              >
                Cancel
              </a>
              <a
                href="#"
                className="card-footer-item"
                onClick={() => {
                  console.log(email);
                  console.log(storyTitle);
                  if (isValidEmail) {
                    save();
                  }
                }}
              >
                Submit
              </a>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}
