import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import FileUploader from "./FileUploader";
import SaveAndShare from "./SaveAndShare";
import SceneLinkList from "./SceneLinkList";
import { StoryGraphPlayer, jsonToStoryGraph } from "./StoryGraphCore";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: "inline-block"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

function getSteps() {
  return ["Upload", "Link", "Play"];
}

function getStepContent(step, builderState, props) {
  const {
    scenes,
    onVidUpload,
    ref,
    generateVideo,
    nodes,
    isGenerated,
    onSceneDelete,
    onNameChange,
    setScene,
  } = builderState;
  switch (step) {
    case 0:
      return (
        <div className="section">
          <FileUploader
            onVidUpload={onVidUpload}
            scenes={scenes}
            onNameChange={onNameChange}
            onSceneDelete={onSceneDelete}
          />
        </div>
      );
    case 1:
      return (
        <div
          className="section"
          style={{ paddingLeft: "0.2em", paddingRight: "0.2em"}}
        >
          <SceneLinkList scenes={scenes} setScene={setScene} />
        </div>
      );
    case 2:
      return (
        <div className="section" style={{ textAlign: "center" }}>
          <button
            className="button is-link"
            onClick={generateVideo}
            disabled={scenes.length === 0}
          >
            Generate
          </button>
          <br />
          <br />
          <div style={{display:'flex', justifyContent:'center'}}>
                   <div
            id="id"
            ref={ref}
            style={{
              pointerEvents: "none",
              boxSizing: "content-box",
              position: "relative",
              width: "80%",
              height: "60vh",
              border: "1px solid red",
                zIndex:2
            }}
          ></div>
          </div>
          <br />
          <br />

          <div>
            {isGenerated && <SaveAndShare {...props} scenes={scenes} nodes={nodes}/>}
          </div>
          <br></br>
        </div>
      );
    default:
      return "Unknown step";
  }
}
export default function StoryGraphBuilder(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [isGenerated, setIsGenerated] = useState(false);
  const [completed, setCompleted] = useState({});
  const [nodes, setNodes] = useState(null);
  const ALPHABET = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
  const [scenes, setScenes] = useState([]);
  const ref = React.createRef();

  const setScene = (oldScene, newScene) => {
    setScenes(
      scenes.map(s => {
        if (oldScene !== s) return s;
        return newScene;
      })
    );
    console.log(scenes);
  };
  const onNameChange = (scene, name) => {
    setScenes(
      scenes.map(s => {
        if (s !== scene) return s;
        const newScene = Object.assign({}, s);
        newScene.name = name;
        return newScene;
      })
    );
  };
  const onSceneDelete = scene => {
    setScenes(scenes.filter(s => s !== scene));
  };
  const onVidUpload = ({ videoFile, src, thumbnail, thumbnailSrc }) => {
    const name = `Scene ${ALPHABET[scenes.length]}`;
    const scene = {
      name,
      videoFile,
      interactiveOptions: {
        style:{},
        toa:5,
        options: [null, null, null]
      },
      options: [],
      src,
      thumbnail,
      thumbnailSrc,
    };
    setScenes(scenes.concat([scene]));
  };

  const generateVideo = () => {
    // TODO: no need for nodes
    for (const node of scenes) {
      node.interactiveOptions.options.forEach((option, i) => {
        if (option) {
          if(option.sceneIndex<0){
          node.interactiveOptions.options[i] = null;            
          }
        }
      });
    }
    console.log('generate Vid ');
    console.log(scenes);
    setNodes(scenes);

    ref.current.style.border = "none";
    
    const storyGraphObj = jsonToStoryGraph({ scenes: scenes.map(s=>s), start: 0 });
    console.log(scenes);
    const storyGraphPlayer = new StoryGraphPlayer({container:ref.current, storyGraphObj});
    setIsGenerated(true);
  };

  const builderState = {
    scenes,
    onVidUpload,
    ref,
    generateVideo,
    nodes,
    isGenerated,
    onNameChange,
    onSceneDelete,
    setScene
  };

  const steps = getSteps();

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep =>
      isLastStep() ? prevActiveStep : prevActiveStep + 1
    );
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStep = step => () => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <div style={styles.stepperContainer}>
        <div>
          <Button
            disabled={activeStep === 0}
            style={{ display: activeStep === 0 ? "none" : "" }}
            onClick={handleBack}
            className={classes.backButton}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={activeStep === steps.length - 1}
            style={{
              display: activeStep === steps.length - 1 ? "none" : ""
            }}
          >
            Next
          </Button>
        </div>
        <Stepper nonLinear alternativeLabel activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                onClick={handleStep(index)}
                completed={completed[index]}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </div>
      <div
        className={classes.instructions}
        style={{ marginTop: 0, marginBottom: 100 }}
      >
        {getStepContent(activeStep, builderState, props)}
      </div>
    </div>
  );
}

const styles = {
  stepperContainer: {
    position: "fixed",
    bottom: 0,
    border: "0px solid red",
    textAlign: "center",
    width: "100%",
    backgroundColor: "white",
    zIndex: 10
  }
};
