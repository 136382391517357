import React, { useState } from "react";

const styles = {
  timeOfActionContainer: {
    padding: 10,
    maxWidth: 350
  },
  control: {
    marginBottom: "0.25em"
  }
};

function SceneInteractivityOptions(props) {
  const { scene, scenes, setScene, color } = props;

  const toa_seconds = new Array(60).fill(0);
  const onLabelChange = (value, selectIndex) => {
    const newScene = Object.assign({}, scene);
    const option = newScene.interactiveOptions.options[selectIndex];
    if (option) {
      option.label = value;
    } else {
      newScene.interactiveOptions.options[selectIndex] = { label: value };
    }
    setScene(newScene);
  };
  const onSceneChange = (value, selectIndex) => {
    const newScene = Object.assign({}, scene);
    const sceneIndex = parseInt(value);
    const option = newScene.interactiveOptions.options[selectIndex];
    if (sceneIndex === -1) {
      option.sceneIndex = -1;
    } else if (option) {
      option.sceneIndex = sceneIndex;
    } else {
      newScene.interactiveOptions.options[selectIndex] = {
        sceneIndex: sceneIndex
      };
    }
    setScene(newScene);
  };
  const onTOAChange = value => {
    const newScene = Object.assign({}, scene);
    const toa = parseInt(value);

    if (value === -1) {
      newScene.interactiveOptions.toa = 5;
    } else {
      newScene.interactiveOptions.toa = toa;
    }
    setScene(newScene);
  };

  return (
    <div style={{ maxWidth: 375, margin: 20}}>
      <div className="level is-mobile" style={{ marginBottom: "1em" }}>
        <div
          className="level-item has-text-centered"
          style={{ marginRight: "0.2em", flexShrink: 0.5 }}
        >
          <div
            style={{
              border: "0px solid red",
              borderRadius: 10,
              backgroundColor: color
            }}
          >
            <p>{scene.name}</p>

            <img
              style={{ borderRadius: 10 }}
              src={scene.thumbnailSrc}
              width="100"
            />
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div style={{ border: "0px solid black" }}>
            <small>interactive options</small>
            <InteractiveButtonOptions
              scenes={scenes}
              onSceneChange={onSceneChange}
              onLabelChange={onLabelChange}
              fieldIndex={0}
            />
            <InteractiveButtonOptions
              scenes={scenes}
              onSceneChange={onSceneChange}
              onLabelChange={onLabelChange}
              fieldIndex={1}
            />
            <InteractiveButtonOptions
              scenes={scenes}
              onSceneChange={onSceneChange}
              onLabelChange={onLabelChange}
              fieldIndex={2}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="control has-icons-left" style={{ width: 170 }}>
          <div className="select">
            <select
              onChange={e => {
                onTOAChange(e.target.value);
              }}
            >
              <option value="-1">Select Time</option>
              {toa_seconds.map((a, i) => (
                <option key={i+"Time"} value={i}>{`${i}`}s</option>
              ))}
            </select>
          </div>
          <span className="icon is-left">
            <i className="fa fa-clock-o"></i>
          </span>
        </div>
      </div>
    </div>
  );
}

function InteractiveButtonOptions(props) {
  const { scene, scenes, onSceneChange, onLabelChange, fieldIndex } = props;
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const COLORS = [
    "#00d1b2",
    "#47c774",
    "#ffdd57",
    "#3174dc",
    "#3298dc",
    "#f14668",
    "#ebfffc",
    "#edf3fc",
    "#effaf3",
    "#fffbeb",
    "#feebf0"
  ];

  return (
    <div className="field is-grouped" style={styles.control}>
      <div className="select" style={{ flexShrink: 0 }}>
        <select
          onChange={e => {
            onSceneChange(e.target.value, fieldIndex);
            setSelectedIndex(parseInt(e.target.value));
            console.log("SELECT");
          }}
          style={{
            backgroundColor: selectedIndex >= 0 ? COLORS[selectedIndex] : "",
          }}
        >
          <option value={-1}>Select Scene</option>
          {scenes.map(({ name }, i) => (
            <option key={i+"InteractiveButtonOptions"} value={i}>{name}</option>
          ))}
        </select>
      </div>
      <div className="control">
        <input
          className="input"
          size="7"
          type="text"
          placeholder="Label"
          onChange={e => {
            onLabelChange(e.target.value, fieldIndex);
          }}
        />
      </div>
    </div>
  );
}

export default function SceneLinkList(props) {
  const scenes = props.scenes;
  const COLORS = [
    "#00d1b2",
    "#47c774",
    "#ffdd57",
    "#3174dc",
    "#3298dc",
    "#f14668",
    "#ebfffc",
    "#edf3fc",
    "#effaf3",
    "#fffbeb",
    "#feebf0"
  ];
  const setScene = props.setScene;
  return (
    <div style={{display:'flex', justifyContent:'space-evenly', flexWrap:'wrap' }}>
      {scenes.map((scene, i) => (
        <SceneInteractivityOptions
          key={i+'SceneInteractivityOptions'}
          scene={scene}
          scenes={scenes}
          color={COLORS[i]}
          setScene={newScene => {
            setScene(scene, newScene);
          }}
        />
      ))}
    </div>
  );
}
