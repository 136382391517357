import React from 'react';
import { Link, useHistory } from "react-router-dom";
import StoryView from "../components/StoryView.js";
import LINK from '../img/link.svg';
import PLAY from '../img/play-share-analyze.svg';
import UPLOAD from '../img/video-upload.svg';
import AGENCY from '../img/collab.svg';
import INDIVIDUAL from '../img/individual.svg';
import Navbar from '../templates/Navbar';
import './LandingPage.css';

export default function LandingPage(props) {
  const history = useHistory();
  const featuredStoryID1 = "t9Unsi0W7CVre1Muteid";
  const featuredStoryID2 = "n0cOWv6yRzCb920FyI3Y";
  return (
    <div className="spotty-background">
                      <Navbar/>

      <div className="purple-bands-background">
        <section className="section">
          <div className="container py-4">
            <div className="columns is-vcentered">
              <div className="column" style={{ textAlign: "center" }}>
                <h2 className="title is-spaced">
                  Interactive Video, <br /> Simplified
                </h2>
                <p className="subtitle">
                  Create engaging, high-quality interactive videos with just a
                  few clicks.
                </p>
                <Link
                  to="/builder"
                  style={{ color: "#000" }}
                  className="button is-primary"
                  href="#"
                >
                  Create your own 🎬
                </Link>
              </div>
              <div
                className="column"
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <div
                  className="box-shadow"
                  style={{
                    position: "relative",
                    borderRadius: 20,
                    overflow: "hidden",
                    width: 268,
                    height: 473
                  }}
                >
                  <StoryView vidHeight={"473px"} storyId={featuredStoryID1} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="section">
        <div className="container py-4">
          <div className="columns is-vcentered">
            <div className="column">
              <h2 className="title is-spaced">What is Interactive Video?</h2>
              <p>
                {" "}
                It's like regular video but with additional elements that the
                viewer can interact with. This new form of media creates a
                more immersive experience and leads to much higher viewer
                engagement. Many top brands spend a lot of time and money
                creating interactive videos for their marketing campaigns.
              </p>{" "}
              <br />
              <p>
                <strong>StoryGraph</strong> greatly lowers the barrier for
                creating interactive videos. Our <strong>Builder</strong> gives
                anyone the power to quickly create from any device. Our{" "}
                <strong>Analytics Dashboard</strong> allows creators to track
                video performance and in-depth viewer engagement analytics. Our{" "}
                <strong>Explore Page</strong> is an ecosystem where creators can
                freely share and and play content.
              </p>
              <br />
              <div style={{ textAlign: "center" }}>
                <Link
                  to="/explore"
                  style={{ backgroundColor: "#6c63ff" }}
                  className="button is-primary"
                  href="#"
                >
                  Explore
                </Link>
              </div>
            </div>
            <div
              className="column"
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                className="box-shadow"
                style={{
                  position: "relative",
                  borderRadius: 20,
                  overflow: "hidden",
                  width: 266,
                  height: 473
                }}
              >
                <StoryView  vidHeight={"473px"} storyId={featuredStoryID2} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container has-text-centered py-4">
          <h2 style={{ marginBottom: "3rem" }} className="title mb-6">
            Who is it for?
          </h2>
          <div className="columns is-multiline is-centered">
            <div className="column is-8 is-6-widescreen mb-5">
              <div className="block">
                <img
                  style={{ maxHeight: 200 }}
                  src={AGENCY}
                  alt=""
                ></img>
              </div>
              <h4 className="subtitle">Digital Agencies</h4>
              <p>
                Iterate faster and more cheaply to push the envelope of the
                digital experience. Gain insights about the content from the
                StoryGraph analytics dashboard 📈
              </p>
            </div>
            <div className="column is-8 is-6-widescreen mb-5">
              <div className="block">
                <img
                  style={{ maxHeight: 200 }}
                  src={INDIVIDUAL}
                  alt=""
                ></img>
              </div>
              <h4 className="subtitle">Savvy Creators</h4>
              <p>
                Get creative. This is another avenue for creators to flex their
                muscles and bring their ideas to life 🎨
              </p>
            </div>
          </div>
          <br></br>
        </div>
      </section>
      <hr></hr>
      <section className="section">
        <div className="container has-text-centered py-4">
          <h2 style={{ marginBottom: "3rem" }} className="title mb-6">
            How does it Work?
          </h2>
          <div className="columns is-multiline is-centered">
            <div className="column is-8 is-4-widescreen mb-5">
              <div className="block">
                <img
                  style={{ maxHeight: 200 }}
                  src={UPLOAD}
                  alt=""
                ></img>
              </div>
              <h4 className="subtitle">
                <small className="number">1</small> Upload Videos
              </h4>
              <p>
                Record and upload individual video clips which represent each
                scene in your interactive video experience.
              </p>
            </div>
            <div className="column is-8 is-4-widescreen mb-5">
              <div className="block">
                <img
                  style={{ maxHeight: 200 }}
                  src={LINK}
                  alt=""
                ></img>
              </div>
              <h4 className="subtitle">
                <small className="number">2</small> Link Scenes
              </h4>
              <p>
                Link scenes together by adding interactive interfaces to each
                scene. Customize your interfaces to help create engaging story
                lines where users can 'Choose their own journey'.
              </p>
            </div>
            <div className="column is-8 is-4-widescreen mb-5">
              <div className="block">
                <img
                  style={{ maxHeight: 200 }}
                  src={PLAY}
                  alt=""
                ></img>
              </div>
              <h4 className="subtitle">
                <small className="number">3</small> Share, Play and Track
              </h4>
              <p>
                Woohoo 🎉 Simply share the link so that everyone can see your
                storygraph! Easily embed your interactive video on a website!
                Track viewer engagement using StoryGraph analytics.
              </p>
            </div>
          </div>
          <div className="buttons is-centered">
            <a
              onClick={() => {
                history.push("/builder");
              }}
              style={{ color: "#000" }}
              className="button is-primary"
              href="#"
            >
              Create your own 🎬
            </a>
          </div>
        </div>
      </section>
      <hr></hr>

      <section className="section" id="contact">
        <div className="container py-4">
          <h2 className="title has-text-centered mb-6">Contact Us </h2>
          <p className="subtitle has-text-centered">
            Let's talk about the future of Interactive Video
          </p>
          <br></br>
          <div className="columns">
            <div className="column is-6">
              <br />
              <div>
                <div className="media">
                  <div className="media-left">
                    <span className="icon is-small">
                      <i className="fa fa-envelope"></i>
                    </span>
                  </div>
                  <div className="media-content">
                    <div className="content">
                      <p>tahjatkinson@gmail.com</p>
                    </div>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">
                    <span className="icon is-small">
                      <i className="fab fa-linkedin"></i>
                    </span>
                  </div>
                  <div className="media-content">
                    <div className="content">
                      <a href="https://www.linkedin.com/in/tahjatkinson/">
                        Tahj Atkinson
                      </a>
                    </div>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">
                    <span className="icon is-small">
                      <i className="fab fa-instagram"></i>
                    </span>
                  </div>
                  <div className="media-content">
                    <div className="content">
                      <p>@tahjytahj</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-5 is-offset-1">
              <form>
                <div className="field">
                  <div className="control">
                    <input className="input" type="email" placeholder="Email" />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <textarea
                      className="textarea"
                      rows="5"
                      placeholder="Write something..."
                    ></textarea>
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <button
                      style={{ backgroundColor: "#6c63ff" }}
                      className="button is-primary is-fullwidth"
                      type="submit"
                      onClick={() => {
                        alert(
                          `Sorry, this form isn't setup as yet. Send me an email at tahjatkinson@gmail.com`
                        );
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
