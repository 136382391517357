
import './StoryGraphCore.css';
/*
- Manages video dimensions
- Switches between videos
- Pause, Play
- 
*/
export class VideoManager {
  constructor(container) {
    this.topVideo = document.createElement("video");
    this.bottomVideo = document.createElement("video");
    this.addClickListeners_();
    this.addVideoElementStyles_();

    // Set z-index
    this.topVideo.style.zIndex = 1;
    this.bottomVideo.style.zIndex = 0;

    container.appendChild(this.topVideo);
    container.appendChild(this.bottomVideo);
  }
  addClickListeners_() {
    [this.topVideo, this.bottomVideo].forEach(video => {
      video.addEventListener("click", e => {
        if (e.target.paused) {
          e.target.play();
        } else {
          e.target.pause();
        }
      });
    });
  }
  pause() {
    this.topVideo.pause();
  }
  play() {
    this.topVideo.play();
  }
  swap() {
    this.topVideo.classList.remove("animate__animated", "animate__fadeIn");
    this.topVideo.classList.add("animate__animated", "animate__fadeOut");
    this.bottomVideo.classList.remove("animate__animated", "animate__fadeOut");
    this.bottomVideo.classList.add("animate__animated", "animate__fadeIn");
    this.topVideo.style.zIndex = 0;
    this.bottomVideo.style.zIndex = 1;
    let tmp = this.topVideo;
    this.topVideo = this.bottomVideo;
    this.bottomVideo = tmp;
    if (!this.bottomVideo.paused) {
      this.bottomVideo.pause();
    }
    this.topVideo.play();
  }
  addVideoElementStyles_() {
    [this.topVideo, this.bottomVideo].forEach(video => {
      video.setAttribute("preload", "none");
      video.setAttribute("playsinline", true);
      video.classList.add("interactive-video");
    });
  }
}

/*
- Capture click events for pause/play
- Create/Hide/Show Inputs
- Have navigation buttons
*/
export class UILayer {
  constructor(container) {
    this.uiContainer = document.createElement("div");
    this.uiContainer.classList.add("ui-container");
    this.optionsContainer = document.createElement("div");
    this.optionsContainer.classList.add("options-container");
    this.uiContainer.appendChild(this.optionsContainer);
    this.topRightContainer = document.createElement("div");
    this.topRightContainer.classList.add("ui-container-top-right");
    this.uiContainer.appendChild(this.topRightContainer);
    this.countdownTimer = new CountdownTimer(this.topRightContainer);

    container.appendChild(this.uiContainer);
  }

  showInteractiveElements(elements, style) {
    const position = style.position;
    for (let element of elements) {
      this.styleElement_(element, style);
      this.optionsContainer.appendChild(element);
    }
    // set container position
    // show container
    this.optionsContainer.style.display = "block";
    this.optionsContainer.classList.remove(
      "animate__animated",
      "animate__fadeOutUp"
    );
    this.optionsContainer.classList.add(
      "animate__animated",
      "animate__fadeInDown"
    );
  }
  hideInteractiveElements() {
    this.optionsContainer.classList.remove(
      "animate__animated",
      "animate__fadeInDown"
    );
    this.optionsContainer.classList.add(
      "animate__animated",
      "animate__fadeOutUp"
    );
    this.optionsContainer.addEventListener(
      "animationend",
      () => {
        while (this.optionsContainer.firstChild) {
          this.optionsContainer.removeChild(this.optionsContainer.firstChild);
        }
        this.optionsContainer.style.display = "none";
      },
      { once: true }
    );
  }
  styleElement_(element, style) { }
  initCountdownTimer(seconds) {
    this.countdownTimer.init(seconds);
  }
}

export class CountdownTimer {
  constructor(container) {
    this.value = 0;
    this.delta = 0;
    this.container = container;
    this.timeoutListener = null;
    this.intervalListener = null;
    // this.progressCircle = new ProgressBar.Circle(this.container, {
    //   strokeWidth: 8,
    //   color: 'white',
    // });
    this.container.style.visibility = "hidden";
    //this.progressCircle.set(1);
  }
  init(seconds) {
    this.show();
    this.progressCircle.set(1);
    this.delta = 1 / (10 * seconds);
    this.value = -1;
    this.intervalListener = setInterval(() => {
      this.decrement();
    }, 100);
    this.timeoutListener = setTimeout(() => {
      this.hide();
      clearTimeout(this.timeoutListener);
      this.timeoutListener = null;
      clearInterval(this.intervalListener);
      this.intervalListener = null;
    }, seconds * 1000);
  }
  decrement() {
    this.value = this.value + this.delta;
    this.progressCircle.set(this.value);
  }
  hide() {
    this.container.style.visibility = "hidden";
  }
  show() {
    this.container.style.visibility = "visible";
  }
}

function progressCircle() {
  const progressCircle = document.createElement("ui-progress-circle");
  progressCircle.setAttribute("value", "33");
  this.topRightContainer.appendChild(progressCircle);
}

export class StoryGraphPlayer {
  constructor(storyGraphPlayerOptions) {
    // TODO take in story graph json and build graph
    this.container = document.createElement("div");
    this.container.classList.add("storygraph-container");
    this.videoManager = new VideoManager(this.container);
    this.uiLayer = new UILayer(this.container);
    this.storyGraph = storyGraphPlayerOptions.storyGraphObj;
    this.handleSelection = storyGraphPlayerOptions.handleSelection;
    this.currentScene = this.storyGraph.scenes[this.storyGraph.start];
    storyGraphPlayerOptions.container.appendChild(this.container);

    this.loadScene(this.currentScene, /* isInit */ true);
  }
  handleSelection(currentScene, selectedScene) {
    if (this.handleSelection)
      return this.handleSelection(currentScene, selectedScene);
  }
  restart() { }
  goToPreviousScene() { }
  buildInteractiveElements(interactiveOptions) {
    switch (interactiveOptions.type) {
      case "BUTTONS":
        return this.buildInteractiveButtons(interactiveOptions);
        break;
      case "TEXT":
        return this.buildInteractiveTextInput(interactiveOptions);
        break;
      default:
        return this.buildInteractiveButtons(interactiveOptions);
    }
  }
  buildInteractiveTextInput(interactiveOptions) {
    const { options, style, toa } = interactiveOptions;
    const elements = [];
    for (let option of options) {
      if (option) {
        const input = document.createElement("input");
        input.classList.add('sg-text-input')
        input.setAttribute("type", "text");
        input.addEventListener("input", $event => {
          if (
            $event.target.value.toLowerCase() === option.value.toLowerCase()
          ) {
            console.log("correct");
            // correct animation
            input.style.backgroundColor = "#baffbf";
            this.loadScene(option.sceneRef, false);
            this.uiLayer.hideInteractiveElements();
          } else {
            input.style.backgroundColor = "#f9cbcb";
          }
        });
        elements.push(input);
      }
    }
    return elements;
  }

  buildInteractiveButtons(interactiveOptions) {
    const { options, style, toa } = interactiveOptions;
    const elements = [];
    for (let option of options) {
      if (option) {
        // create button
        const button = document.createElement("button");
        button.innerText = option.label;
        // Set default style
        button.classList.add("btn");
        button.addEventListener(
          "click",
          () => {
            console.log(option);
            if (option.url) {
              console.log('opening url');
              window.open(option.url, "_blank");
              this.videoManager.pause();
            } else {
              console.log('loading scene');
              this.loadScene(option.sceneRef, false);
              this.uiLayer.hideInteractiveElements();
            }
          },
          { once: !option.url }
        );
        elements.push(button);
      }
    }
    return elements;
  }
  loadScene(scene, isInit) {
    const { interactiveOptions, src } = scene;
    const { style, toa, countdown } = interactiveOptions;
    const elements = this.buildInteractiveElements(interactiveOptions);
    const video = isInit
      ? this.videoManager.topVideo
      : this.videoManager.bottomVideo;
    const onTimeUpdate = () => {
      //console.log(video.currentTime);
      if (video.currentTime > toa) {
        this.uiLayer.showInteractiveElements(elements, style);
        if (countdown) {
          // pass autoswitch callback
          this.uiLayer.initCountdownTimer(countdown);
        }
        video.removeEventListener("timeupdate", onTimeUpdate);
      }
    };
    video.addEventListener("timeupdate", onTimeUpdate);
    video.addEventListener(
      "canplay",
      () => {
        if (isInit) {
          const playButtonContainer = document.createElement("div");
          const playButton = document.createElement("div");
          playButton.classList.add("play-button");
          playButtonContainer.classList.add("play-button-container");
          playButtonContainer.appendChild(playButton);
          this.container.appendChild(playButtonContainer);
          this.videoManager.topVideo.addEventListener(
            "click",
            () => {
              playButtonContainer.remove();
            },
            { once: true }
          );
        } else {
          this.videoManager.swap();
          this.currentScene = scene;
        }
      },
      { once: true }
    );
    video.src = src;
    video.load();
  }
}

const storyGraphToJson = storyGraph => {
  const storyGraphJson = Object.assign({}, storyGraph);
  storyGraphJson.scenes.forEach(scene => {
    scene.interactiveOptions.options = scene.interactiveOptions.options.map(
      sceneOption => {
        if (sceneOption) {
          const sceneRef = sceneOption.sceneRef;
          if (!sceneRef) return sceneOption;

          const sceneIndex = storyGraphJson.scenes.findIndex(
            scene => sceneRef === scene
          );
          if (sceneIndex < 0) {
            throw new Error("Scene reference not in scenes");
          }
          const sceneOptionJson = Object.assign({}, sceneOption);
          delete sceneOptionJson.sceneRef;
          sceneOptionJson.sceneIndex = sceneIndex;
          return sceneOptionJson;
        }
      }
    );
  });
  return storyGraphJson;
};

const jsonToStoryGraph = storyGraph => {
  storyGraph.scenes.forEach(scene => {
    scene.interactiveOptions.options.forEach(sceneOption => {
      if (sceneOption) {
        const sceneIndex = sceneOption.sceneIndex;
        if (!sceneIndex && sceneIndex !== 0) return;
        const sceneRef = storyGraph.scenes[sceneIndex];
        if (!sceneRef) {
          throw new Error("Invalid sceneIndex");
        }
        sceneOption.sceneRef = sceneRef;
      }
    });
  });
  return storyGraph;
};

export { storyGraphToJson, jsonToStoryGraph };
