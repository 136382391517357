import React, { useState } from "react";
import About from "../templates/About";

export default function AboutModal() {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <button
        className="button"
        onClick={() => {
          setIsActive(true);
        }}
      >
        <span className="icon is-small">
          <i className="fas fa-info-circle" style={{color:"#6192ff"}}></i>
        </span>
      </button>
      <div className={"modal " + (isActive ? "is-active" : "")}>
        <div className="modal-background"></div>
        <div className="modal-card" style={{padding:20}}>
          <header className="modal-card-head">
            <p className="modal-card-title">HOW TO</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                setIsActive(false);
              }}
            ></button>
          </header>
          <section className="modal-card-body">
            <About />
          </section>
          <footer className="modal-card-foot">
            <button
              className="button"
              onClick={() => {
                setIsActive(false);
              }}
            >
              Close
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
}
