import React, { useState } from "react";
import ThumbnailGenerator from "./ThumbnailGenerator";

const COLORS = [
  "#00d1b2",
  "#3174dc",
  "#3298dc",
  "#47c774",
  "#ffdd57",
  "#f14668",
  "#ebfffc",
  "#edf3fc",
  "#effaf3",
  "#fffbeb",
  "#feebf0"
];

export default function FileUploader(props) {
  let [vidData, setVidData] = useState(null);
  const onVidUpload = props.onVidUpload;

  const onFileChange = event => {
    const videoFile = event.target.files[0];
    const src = URL.createObjectURL(videoFile);
    console.log(src);
    setVidData({ videoFile, src });

    event.target.value = "";
  };
  return (
    <div style={styles.container}>
      <div>
        <ThumbnailGenerator
          src={vidData ? vidData.src : null}
          onThumbnail={({ thumbnail, thumbnailSrc }) => {
            console.log(thumbnail);
            const data = Object.assign({}, vidData);
            setVidData(null);
            setTimeout(() => {
              onVidUpload({
                videoFile: data.videoFile,
                src: data.src,
                thumbnail,
                thumbnailSrc
              });
            }, 0);
          }}
        />
      </div>
      <div style={styles.uploadButton}>
        <input
          style={{ display: "none" }}
          type="file"
          id="file-upload"
          onChange={onFileChange}
          accept=".mp4, .mov"
        />
        Click to upload mp4
        <label
          style={{
            cursor: "pointer",
            margin: 10,
            borderRadius: 10,
            display: "block",
            textAlign: "center",
            width: 100,
            height: 100,
            backgroundColor: "#dee5f9",
            border: "1px dashed #6f8ee6",
            paddingTop: 32
          }}
          htmlFor="file-upload"
        >
          <i
            className="fa fa-plus-circle"
            style={{ fontSize: "2em", color: "#6f8ee6" }}
          ></i>
        </label>
        <br></br>
      </div>
      <SceneVideoList {...props} />
    </div>
  );
}

const styles = {
  uploadButton: {
    padding: 0
  },
  videoListContainer: {
    padding: 20,
    paddingTop: 0,
    display: 'flex',
    justifyContent:'space-between',
  }
};

function SceneVideoList(props) {
  const { scenes, onNameChange, onSceneDelete } = props;
  return (
    <div style={styles.videoListContainer}>
      {!!scenes.length && (
        <div>
          <p>Uploaded Videos</p> <div style={{display:'flex', justifyContent:'space-evenly', flexWrap:'wrap'}}>
          {scenes.map((scene, index) => (
            <div
              className="notification"
              style={{
                margin: 10,
                minWidth: 214
              }}
              key={index+"SceneVideoList"}
            >
              <button
                className="delete"
                onClick={() => onSceneDelete(scene)}
              ></button>
              <div className="field" style={{maxWidth:150}}>
                <div className="control">
                  <p className="help">Scene Title</p>

                  <input
                    className="input"
                    type="text"
                    value={scene.name}
                    onChange={e => {
                      onNameChange(scene, e.target.value);
                    }}
                  />
                </div>
              </div>
              <span style={{ display: "block", textAlign: "center" }}>
                {" "}
                <img
                  style={{ borderRadius: 10 }}
                  src={scene.thumbnailSrc}
                  width="100"
                />
              </span>
            </div>
          ))}
        </div></div>
      )}
    </div>
  );
}
